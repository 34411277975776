html,
body {
  margin: 0;
  padding: 0;
  text-align: left;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
  color: #1b1c1e;
  background: white;
}

img {
  border: none;
}

ul,
ol,
li,
p {
  margin: 0;
  padding: 0;
}

input,
textarea {
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  font-size: 20px;
}

::placeholder {
  color: #aaa;
}

select {
  font-size: 20px;
  -webkit-appearance: none;
}

a {
  color: #49a4e7;
  cursor: pointer;
  text-decoration: underline;
}

a:hover {
  text-decoration: none;
}

button {
  cursor: pointer;
}

/* .underline {
  text-decoration: underline;
  transition: all 0.2s ease-in-out;
}

.underline:hover {
  text-decoration: none;
} */

#app {
  width: 100%;
}

/* enter and etc */
.login .header_login {
  margin: 0 0 70px;
  padding: 30px 0;
  text-align: center;
  color: white;
  background: #33384b;
}

.login .header_login h1 {
  margin: 0 0 30px;
  font-size: 55px;
  line-height: 55px;
}

.login .header_login h2 {
  margin: 0;
  font-size: 40px;
  line-height: 40px;
}

.login .content_login {
  margin: 0 auto 100px;
  width: 400px;
}

.login .content_login .info {
  margin: 0 0 30px;
  line-height: 24px;
}

.login .content_login input[type="text"],
.content_login input[type="password"],
.content_login input[type="email"] {
  margin: 7px 0 0;
  width: 100%;
  padding: 7px 10px;
  border: 2px solid #ababab;
  border-radius: 2px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
}

.login .content_login .button {
  margin: 0 0 30px;
}

.login .content_login button {
  margin-top: 10px;
  width: 100%;
  padding: 15px;
  background: #1dc742;
  border: none;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
}

.login .content_login button:hover {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
}

.login .content_login .link {
  text-align: center;
}

.login .content_login .eye {
  display: inline-block;
  position: relative;
  top: 7px;
  margin: 0 0 0 -45px;
  width: 30px;
  height: 30px;
  background: url(images/icon-eye-closed.svg) no-repeat 0 0;
  background-size: cover;
  transition: all 0.2s ease-in-out;
}

.login .content_login .eye.opened {
  background: url(images/icon-eye-opened.svg) no-repeat 0 0;
  background-size: cover;
}

.login .content_login .eye:hover {
  transform: scale(1.2);
}

.login .content_login .forgot {
  display: block;
  margin: 7px 0 0;
  text-align: right;
  font-size: 16px;
}

/* contractor part */

label,
.hdr {
  display: block;
  font-size: 16px;
}

.label {
  color: #969696;
}

.hdr {
  color: #ffffff;
}

/* enter and etc */
.contractor .header_contractor {
  margin: 0;
  padding: 30px 0;
  text-align: center;
  color: white;
  background: #33384b;
}

.contractor .header_contractor h1 {
  margin: 0 0 30px;
  font-size: 55px;
  line-height: 55px;
}

.contractor .header_contractor h2 {
  margin: 0;
  font-size: 40px;
  line-height: 40px;
}

.content {
  margin: 0 auto;
  max-width: 1920px;
  padding: 0 45px 120px;
  line-height: 27px;
  background: white;
}

.content h3 {
  margin: 0 0 30px;
  font-size: 20px;
}

/* data */

.field {
  margin: 0 0 30px;
}

.content .two_col {
  display: flex;
  margin: 0 -20px;
  width: 100%;
}

.content .two_col .col {
  padding: 0 20px;
  width: 50%;
}

.contractor .content .buttons {
  text-align: center;
}

.content .buttons button {
  padding: 15px 45px;
}

/*
.content .photos {display: grid; grid-template-columns: 22% 22% 22% 22%; grid-template-rows: auto; column-gap: 4%; row-gap: 8%; margin: 7px 0 70px;}
.content .photos img {width: 100%; height: 100%; object-fit: cover;}

.content .photos .add {padding: 20px; box-sizing: border-box; text-align: center; text-decoration: none; font-size: 16px; color: #33384B; border: 2px dashed #969696;}
.content .photos .add .plus {display: block; font-size: 100px; line-height: 100px;}
*/

/* main part */
span.spl {
  display: inline-block;
  width: 40px;
}

.err {
  color: #ff8989;
}

.ok {
  color: #1dc742;
}

.info {
  font-size: 16px;
}

button {
  padding: 15px 0;
  box-sizing: border-box;
  text-align: center;
  font-size: 20px;
  color: white;
  border: none;
  border-radius: 2px;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.2s ease-in-out;
}

button:hover {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
}

button:disabled {
  background: #969696 !important;
  border: 2px solid #969696 !important;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25) !important;
}

.react-date-picker {
  width: 100% !important;
}

/*


colors
======

black instead: #1b1c1e

dark blue bk: #33384B

blue btn: #49A4E7

green btn: #1DC742

red btn and link: #FF8989

grey color (label, disabled): #969696

link color: #6B8CFF

input border: #ABABAB

placeholder: #bbb

cation bk: #ACDCFF

image plus: #33384B

light grey background: #F1F1F1






font sizes
==========

desktop
-------

content header: 30px

label size: 16px

content text and btn size: 
    font-size: 20px;
    line-height: 27px;

popup text:
    font-size: 30px;
    line-height: 45px;

caption size: 30px;






tablet
------




phone
-----




*/

/* main content */

/* header */
.header {
  position: relative;
  z-index: 4;
  height: 60px;
  padding: 0 45px;
  color: white;
  background: #1b1c1e;
}

.header .back {
  position: absolute;
  left: 25px;
  top: 20px;
  font-size: 16px;
}

.header .back a {
  display: inline-block;
  padding-left: 20px;
  text-decoration: none;
  color: #a9d7f9;
  background: url(images/arrow-back-header.svg) no-repeat 0 50%;
}

.header .menu {
  position: absolute;
  left: 45px;
  top: 20px;
  font-size: 16px;
}

.header .menu li {
  display: inline-block;
  margin-right: 40px;
  list-style: none;
}

.header .menu li a {
  display: inline-block;
  padding-left: 30px;
  padding-top: 5px;
  padding-bottom: 5px;
  text-decoration: none;
  color: #a9d7f9;
}

.header .menu li a:hover {
  text-decoration: underline;
}

.header .menu li.objects a {
  background: url(images/icon-objects.svg) no-repeat 0 50%;
  background-size: 20px 20px;
}

.header .menu li.users a {
  background: url(images/icon-users.svg) no-repeat 0 50%;
  background-size: 20px 20px;
}

.header .user {
  position: absolute;
  right: 30px;
  top: 0;
  padding: 17px 30px 17px 15px;
  font-size: 20px;
  color: #fcffd7;
  background: url(images/arrow-down-yellow.svg) no-repeat;
  background-position: right center;
  border-radius: 0 0 2px 2px;
}

.header .user .drop_down {
  display: none;
  position: absolute;
  left: 0;
  top: 50px;
  width: 100%;
  padding: 15px 15px 0;
  box-sizing: border-box;
  background: #fcffd7;
  border-radius: 0 0 2px 2px;
  list-style: none;
}

.header .user .drop_down li {
  margin: 0 0 20px;
  font-size: 16px;
  color: #969696;
}

.header .user .drop_down a {
  text-decoration: none;
  color: #1b1c1e;
}

.header .user .drop_down a:hover {
  text-decoration: underline;
}

.header .user:hover .drop_down {
  display: block;
}

/* header2 */
.header2 {
  position: relative;
  z-index: 3;
  height: auto;
  padding: 0 30px 20px 45px;
  color: white;
  background: #33384b;
}

.header2 ul {
  display: flex;
  justify-content: space-between;
  padding: 20px 0 0;
  font-size: 20px;
  list-style: none;
}

.header2 li.hdr {
  padding-right: 100px;
  font-weight: bold;
}

.header2 li.hdr a {
  margin-left: -20px;
  padding: 0 0 0 20px;
  background: url(images/arrow-back-header.svg) no-repeat 0 50%;
}

.header2 li.hdr .status {
  display: block;
  font-weight: normal;
  font-size: 16px;
  opacity: 0.5;
}

.header2 li.hdr .under_header {
  display: block;
  margin-top: 5px;
  font-weight: normal;
  font-size: 16px;
  opacity: 0.5;
}

.header2 li.hdr .under_header a {
  text-decoration: underline;
}

.header2 ul .menu {
  display: flex;
  align-items: center;
}

.header2 ul li {
  padding-right: 50px;
}

.header2 ul li a {
  text-decoration: none;
  color: white;
}

.header2 ul li a:hover {
  text-decoration: underline;
}

.header2 ul .menu li {
  padding-left: 50px;
  padding-right: 0;
}

.header2 li.burger {
  padding-right: 0;
}

.header2 li.burger a {
  display: none;
}

.header2 li.btn {
  padding-right: 0;
}

.header2 li.btn button {
  padding: 7px 15px;
  font-size: 16px;
  background: #49a4e7;
}

/* tabs */
.tabs {
  display: flex;
  align-items: flex-end;
  position: relative;
  z-index: 1;
  height: 85px;
  padding: 0 10px;
  background: #33384b;
  list-style: none;
}

.tabs li {
  font-size: 20px;
  line-height: 20px;
}

.tabs a {
  display: inline-block;
  margin: 0 5px 0 15px;
  padding: 15px 19px 12px;
  text-decoration: none;
  color: white;
}

.tabs a.sel,
.tabs a:hover {
  color: #1b1c1e;
  background: white;
  border-radius: 2px 2px 0 0;
  box-shadow: 0 -10px 10px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;
}

.plan {
  margin: 10px 0 50px;
  max-width: 910px;
}

.plan button.show {
  margin: 0 0 20px;
  padding: 5px 10px;
  vertical-align: middle;
  text-decoration: none;
  font-size: 16px;
  color: white;
  background: #49a4e7;
}

.plan .scroll .container {
  float: left;
  position: relative;
  min-width: 100%;
}

.plan .scroll img {
  display: block;
  width: 100%;
}

.plan .control {
  display: block;
  clear: both;
  position: relative;
  height: 35px;
  border-top: 4px solid #969696;
}

.plan .control .zoom {
  position: absolute;
  left: 12px;
  top: 0;
  width: 35px;
  height: 25px;
  background: url(images/icon-zoom.svg) no-repeat 50% 50% #f1f1f1;
  background-size: 25px 25px;
  border-radius: 0 0 2px 2px;
}

.plan .control .remove {
  position: absolute;
  right: 12px;
  top: 0;
  padding: 5px 10px;
  font-size: 16px;
  text-decoration: none;
  color: white;
  background: #ff8989;
  border-radius: 0 0 2px 2px;
}

.plan .control .show_pins {
  position: absolute;
  right: 12px;
  top: 0;
  padding: 5px 10px;
  font-size: 16px;
  text-decoration: none;
  color: white;
  background: #49a4e7;
  border-radius: 0 0 2px 2px;
}

.plan .pins {
  display: flex;
  justify-content: space-evenly;
  padding: 20px 20px 17px;
  background: #f1f1f1;
}

.plan .pins .pin {
  white-space: nowrap;
}

.plan .pins .pin img {
  display: inline-block;
  margin: 0 10px 0 0;
  width: 20px;
  vertical-align: middle;
}

.plan .the_pin {
  position: absolute;
  width: 25px;
  height: 40px;
}

.plan .the_pin.revision {
  background: url(images/pin-revision.png) no-repeat 0 0;
  background-size: cover;
}

.plan .the_pin.claim {
  background: url(images/pin-claim.png) no-repeat 0 0;
  background-size: cover;
}

.plan .the_pin.act {
  background: url(images/pin-act.png) no-repeat 0 0;
  background-size: cover;
}

.plan .the_pin.work {
  background: url(images/pin-work.png) no-repeat 0 0;
  background-size: cover;
}

.plan .the_pin.many {
  background: url(images/pin-many.png) no-repeat 0 0;
  background-size: cover;
}

.plan.zoomed {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
  margin: 0;
  width: 100%;
  max-width: none;
  height: 100%;
  padding: 20px;
  overflow: hidden;
  box-sizing: border-box;
  background: white;
}

.plan.zoomed .scroll {
  height: calc(100% - 100px);
  overflow: scroll;
}

.plan.zoomed .scroll img {
  width: auto;
  min-width: 100%;
}

.plan.zoomed .control .zoom {
  background: url(images/icon-zoom-out.svg) no-repeat 50% 50% #f1f1f1;
  background-size: 25px 25px;
}

.content {
  z-index: 2;
  padding: 60px 45px 120px;
}

.buttons {
  position: relative;
  margin: 60px 0 30px;
}

.buttons.top {
  margin-top: 0;
}

.buttons button.link {
  display: inline-block;
  min-width: auto;
  text-align: left;
  text-decoration: underline;
  color: #49a4e7;
  background: none;
  box-shadow: none;
  padding: 0;
}

.buttons .link:hover {
  box-shadow: none;
  text-decoration: none;
}

.buttons.sized button {
  width: 220px;
}

.buttons .green {
  display: inline-block;
  background: #1dc742;
  border: 2px solid #1dc742;
}

.buttons .blue {
  display: inline-block;
  background: #49a4e7;
  border: 2px solid #49a4e7;
}

.buttons .red {
  display: inline-block;
  background: #ff8989;
  border: 2px solid #ff8989;
}

.buttons .cancel {
  display: inline-block;
  color: #49a4e7;
  background: white;
  border: 2px solid #49a4e7;
}

.buttons .remove {
  display: block;
  position: absolute;
  right: 0;
  top: 3px;
  margin: 0;
  width: 35px;
  min-width: 0;
  height: 35px;
  padding: 0;
  background: url(images/icon-remove.svg) no-repeat 0 0;
  background-size: contain;
  box-shadow: none;
  border: none;
}

.buttons .remove:disabled {
  background: url(images/icon-remove.svg) no-repeat 0 0 !important;
  background-size: cover !important;
  border: none !important;
  opacity: 0.2;
  box-shadow: none !important;
}

.buttons .remove:hover {
  box-shadow: none;
  transform: scale(1.1);
}

.buttons .remove:disabled:hover {
  transform: scale(1);
}

.buttons button {
  margin: 0 20px;
  min-width: 110px;
  padding-left: 15px;
  padding-right: 15px;
  text-decoration: none;
  line-height: 100%;
  border-radius: 2px;
}

.content .buttons button {
  margin: 0 30px 0 0;
}

/* user change password */

.content .change_password {
  margin: 0 auto;
  width: 100%;
  max-width: 400px;
}

.content .change_password input[type="text"],
.content .change_password input[type="password"] {
  margin: 7px 0 0;
  width: 100%;
  padding: 7px 10px;
  border: 2px solid #ababab;
  border-radius: 2px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
}

.content .change_password hr {
  margin: 40px 0 30px;
  height: 2px;
  background: #ababab;
  border: none;
}

.content .change_password .eye {
  display: inline-block;
  position: relative;
  top: 7px;
  margin: 0 0 0 -45px;
  width: 30px;
  height: 30px;
  background: url(images/icon-eye-closed.svg) no-repeat 0 0;
  background-size: cover;
  transition: all 0.2s ease-in-out;
}

.content .change_password .eye.opened {
  background: url(images/icon-eye-opened.svg) no-repeat 0 0;
  background-size: cover;
}

.content .change_password .eye:hover {
  transform: scale(1.2);
}

.content .change_password button {
  margin-top: 20px;
  width: 100%;
  padding: 15px;
  color: white;
  background: #1dc742;
  border: none;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
  transition: box-shadow 0.2s ease-in-out;
}

.content .change_password button:hover {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
}

/* standart table */

.content table.list {
  margin: 0 -15px 60px;
  width: calc(100% + 30px);
  border-collapse: collapse;
}

.content table.list tr:nth-child(even) {
  background: #f1f1f1;
}

.content table.list th {
  padding: 15px;
  text-align: left;
  vertical-align: bottom;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  color: #969696;
}

.content table.list td {
  padding: 15px;
  text-align: left;
  vertical-align: middle;
}

.content table.list.multiline td {
  vertical-align: top;
}

.content table.list .mobile {
  display: none;
}

.content table.list button {
  padding: 5px 10px;
  vertical-align: middle;
  text-decoration: none;
  font-size: 16px;
  color: white;
  background: #49a4e7;
}

.content table.list button.remove {
  display: inline-block;
  margin-left: 20px;
  width: 20px;
  height: 20px;
  vertical-align: middle;
  background: url(images/icon-remove.svg) no-repeat 0 0;
  background-size: cover;
  box-shadow: none;
}

.content table.list button.remove:hover {
  box-shadow: none;
  transform: scale(1.1);
}

.content table.list .icon {
  padding-right: 0;
}

.content table.list .icon img {
  width: 36px;
}

img.avatar {
  margin-right: 15px;
  width: 36px;
  height: 36px;
  object-fit: cover;
  vertical-align: middle;
  border-radius: 100px;
}

span.avatar {
  display: inline-block;
  margin-right: 15px;
  width: 36px;
  height: 36px;
  padding: 8px 0 0;
  box-sizing: border-box;
  overflow: hidden;
  text-align: center;
  vertical-align: middle;
  font-size: 20px;
  line-height: 20px;
  color: #1b1c1e;
  background: #aaa;
  border-radius: 100px;
}

img.avatar.in_list,
span.avatar.in_list {
  margin-right: -12px;
}

span.avatar:hover,
img.avatar:hover {
  position: relative;
  z-index: 2;
  transform: scale(1.05);
}

.content table.list small {
  display: block;
}

/* subs */
.subs {
  margin: 0 0 0 -20px;
}

.subs .sub_group {
  margin: 0 0 25px 20px;
}

.subs .sub_group .hdr {
  margin: 10px 0 15px 0;
  padding: 0 0 0 20px;
  font-size: 20px;
  color: #1b1c1e;
  background: url(images/arrow-right.svg) no-repeat 0 7px;
  background-size: 8px 13px;
  cursor: pointer;
}

.subs .sub_group .hdr:hover {
  color: #49a4e7;
}

.subs .sub_group .hdr.sel {
  background: url(images/arrow-down.svg) no-repeat 0 7px;
  background-size: 13px 8px;
}

.subs .sub_group .hdr button {
  margin-left: 10px;
  padding: 5px 10px;
  vertical-align: middle;
  text-decoration: none;
  font-size: 16px;
  background: #49a4e7;
}

.subs .sub_group .hdr button.remove {
  display: inline-block;
  margin-left: 20px;
  width: 20px;
  height: 20px;
  vertical-align: middle;
  background: url(images/icon-remove.svg) no-repeat 0 0;
  background-size: cover;
  box-shadow: none;
}

.subs .sub_group .hdr button.remove:hover {
  box-shadow: none;
  transform: scale(1.1);
}

.subs .sub_item {
  margin: 0 0 15px 20px;
}

.subs .sub_item small {
  display: block;
  color: #969696;
}

.subs .sub_item small span {
  display: inline-block;
  width: 120px;
}

/* data */
.content div.hdr {
  margin: 7px 0 0;
}

.content div .data {
  display: block;
  margin: 7px 0 0;
}

.content label {
  margin-right: 30px;
}

.content .navigate {
  margin: 0 0 30px -10px;
}

.content .navigate span {
  display: inline-block;
  margin: 0 20px 0 0;
  padding: 7px 10px;
}

.content .navigate span.sel {
  background: #f1f1f1;
}

/* graph for jobs */

.content .only_my {
  margin: 0 0 40px;
}

.content .graph {
  position: relative;
  margin: 0 -15px;
  overflow-x: scroll;
  overflow-y: visible;
}

.content .graph table {
  position: relative;
  table-layout: fixed;
  width: auto;
  border-collapse: collapse;
}

.content .graph table .hdr {
  font-weight: bold;
}

.content .graph table .hdr .time span {
  display: inline-block;
  width: 100px;
}

.content .graph table td.project {
  position: sticky;
  left: 0;
  z-index: 3;
  padding: 15px;
  background: white;
  box-shadow: 4px 0 0 0 #ddd;
}

.content .graph table .hdr td .project-name {
  margin-right: 45px;
  color: #000000;
  white-space: nowrap;
}

.content .graph table td {
  text-align: left;
  vertical-align: top;
}

.content .graph table td.jobs {
  position: sticky;
  left: 0;
  z-index: 3;
  padding: 15px;
  background: white;
  box-shadow: 4px 0 0 0 #ddd;
}

.content .graph table td.jobs span {
  display: inline-block;
}

.content .graph table td.jobs .group {
  display: block;
  width: 300px;
  padding-right: 75px;
}

.content .graph table td.jobs .group.closed {
  background: url(images/arrow-right.svg) no-repeat 0 0;
}

.content .graph table td.jobs .group.open {
  background: url(images/arrow-down.svg) no-repeat 0 0;
}

.content .graph table td.jobs .level {
  padding-left: 20px;
}

.content .graph table tr.invis {
  display: none;
}

.content .graph table a {
  color: black;
}

.content .graph table td.time {
  position: relative;
  padding: 15px 15px 15px 30px;
}

.content .graph table td.time span.bk {
  display: block;
  position: absolute;
  /* left: 55.2px; */
  left: 50.15px;
  top: 0;
  z-index: 1;
  height: 100%;
  width: 500%;
  background: url(images/graph-line.svg) repeat 0 0;
  /* background-size: 251.2px auto; */
  background-size: 175.65px auto;
  transform-origin: left;
  transform: scaleX(0.2);
}

.content .graph table td.time span.col {
  position: relative;
  z-index: 2;
}

.content .graph .timing {
  display: block;
  height: 27px;
  background: green;
}

.content .graph .add {
  display: inline-block;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 30px;
  height: 30px;
  background: url(images/icon-add.svg) no-repeat 0 0;
  background-size: cover;
}

.content .graph .edit {
  display: inline-block;
  position: absolute;
  right: 55px;
  top: 15px;
  width: 30px;
  height: 30px;
  background: url(images/icon-edit.svg) no-repeat 0 0;
  background-size: cover;
}

.main .content p {
  margin: 0 0 30px;
}

.main .content h2 {
  margin: 0 0 30px;
  font-size: 30px;
}

.main .content h3 {
  margin: 50px 0 10px;
}

.content .two_col_field {
  margin-bottom: 30px;
}

.content .two_col_field .cols {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.content .job_buttons {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  margin: 0 -15px 60px;
}

.content .job_buttons .green {
  display: inline-block;
  margin: 0 15px;
  width: 100%;
  background: #1dc742;
}

.content .job_buttons .red {
  display: inline-block;
  margin: 0 15px;
  width: 100%;
  background: #ff8989;
}

.content .job_desc {
  margin: 0 0 30px;
}

.content .job_desc .date {
  display: inline-block;
  margin: 0 0 7px;
  width: 200px;
  color: #a9a9a9;
}

.content .job_desc .status {
  margin: 0 0 7px;
}

.content .job_desc .name {
  display: block;
  margin: 0 0 7px;
}

.content .job_desc .desc {
  display: block;
}

.under_popup {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.popup .buttons,
.popup_small .buttons {
  display: flex;
  justify-content: center;
}

.popup .buttons.in_form {
  margin-top: -10px;
  justify-content: flex-end;
}

.popup .buttons.in_form button {
  margin: 0 0 0 40px;
  padding: 10px 30px;
}

.popup .buttons.left {
  justify-content: flex-start;
  margin-bottom: 30px;
  margin-top: 0;
}

.popup .buttons.left button {
  margin-left: 0;
  margin-right: 40px;
}

.popup .buttons.vertical {
  justify-content: flex-start;
  flex-direction: column;
}

.popup .buttons.vertical button {
  margin-left: 0;
  margin-bottom: 20px;
}

.popup_small {
  position: fixed;
  left: calc(50% - 280px);
  top: 10vh;
  z-index: 101;
  width: 560px;
  padding: 45px 25px 25px;
  box-sizing: border-box;
  background: white;
  border-radius: 2px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.popup_small .close {
  display: block;
  position: absolute;
  right: 25px;
  top: 25px;
  width: 20px;
  height: 20px;
  background: url(images/icon-close.svg) no-repeat 0 0;
  box-shadow: none;
}

.popup_small .close:hover {
  box-shadow: none;
}

.popup_small .message {
  margin: 0 0 45px;
  text-align: center;
  font-size: 30px;
  line-height: 45px;
}

.popup_small .message.small {
  margin: -20px 0 30px;
  font-size: 20px;
}

.popup_small .buttons {
  margin: 0 0 20px;
}

.popup_small .field {
  margin: -20px 0 45px;
}

.popup_small .field input[type="text"],
.popup_small .field input[type="number"],
.popup_small .field input[type="email"] {
  margin: 7px 0 0;
  width: 100%;
  padding: 7px 10px;
  border: 2px solid #ababab;
  border-radius: 2px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
}

.popup_small .field textarea {
  margin: 7px 0 0;
  width: 100%;
  height: 100px;
  padding: 7px 10px;
  border: 2px solid #ababab;
  border-radius: 2px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
}

.popup {
  overflow-x: hidden;
  position: absolute;
  left: calc(50% - 494px);
  top: 1vh;
  z-index: 101;
  width: 988px;
  padding: 45px 45px 15px;
  box-sizing: border-box;
  background: white;
  border-radius: 2px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.popup .connect {
  margin: 0 0 20px;
}

.popup .connect button {
  margin: 0 10px 10px 0;
  padding: 7px 15px;
  color: #1b1c1e;
  background: white;
  border: 2px solid #acdcff;
}

.popup .connect button.sel {
  color: #1b1c1e;
  background: #acdcff;
}

.popup .caption {
  position: relative;
  margin: -45px -45px 45px;
  padding: 20px 45px;
  font-weight: bold;
  font-size: 30px;
  line-height: 30px;
  background: #acdcff;
  border-radius: 2px 2px 0 0;
}

.popup .caption .form {
  display: inline-block;
  margin: 0 0 0 30px;
  font-weight: normal;
}

.popup .caption .form label {
  color: #1b1c1e;
}

.popup .caption .form input[type="checkbox"] {
  margin-right: 10px;
}

.popup .caption .close {
  display: block;
  position: absolute;
  right: 25px;
  top: 25px;
  width: 20px;
  height: 20px;
  background: url(images/icon-close.svg) no-repeat 0 0;
  box-shadow: none;
}

.popup .caption .close:hover {
  box-shadow: none;
}

.popup .warning {
  margin: -45px -45px 45px;
  padding: 10px 45px;
  color: white;
  background: #ff8989;
}

.popup .info {
  margin: 0 0 30px;
}

.popup .info.err {
  margin-top: 7px;
}

.popup .field.appear {
  position: relative;
}

.popup .field.appear::after {
  content: "";
  position: absolute;
  top: -10px;
  left: -44px;
  right: -44px;
  bottom: -10px;
  background: #f1f1f1;
  z-index: -1;
}

.popup .hdr {
  margin: 0 0 7px;
}

.popup .field label {
  display: inline-block;
  margin-right: 20px;
}

.popup .data {
  display: block;
  margin: 7px 0 0;
}

.popup .data small {
  display: block;
  margin: 7px 0 25px;
}

.popup .two_col {
  display: flex;
  justify-content: space-between;
  margin: 0 -20px 0;
}

.popup .two_col .col {
  padding: 0 20px;
  width: 50%;
}

.popup .two_col .user_photo {
  display: block;
  margin: 7px 0 0;
}

.popup .two_col.password {
  margin: 0 -45px 45px;
  padding: 30px 20px;
  background: #f1f1f1;
}

.popup .two_col.password .err {
  display: block;
  margin: 7px 0 0;
}

.popup input[type="radio"] {
  margin-right: 5px;
  vertical-align: 1px;
}

.popup input[type="text"],
.popup input[type="password"],
.popup input[type="number"],
.popup input[type="email"],
.popup input[type="time"] {
  margin: 7px 0 0;
  width: 100%;
  padding: 7px 10px;
  border: 2px solid #ababab;
  border-radius: 2px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
}

.popup select {
  position: relative;
  z-index: 2;
  margin: 7px 0 0;
  width: 100%;
  padding: 7px 10px;
  background: transparent;
  border: 2px solid #ababab;
  border-radius: 2px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
}

.popup .select_arrow {
  display: inline-block;
  position: relative;
  z-index: 1;
  margin: 0 0 0 -30px;
  width: 20px;
  height: 15px;
  background: url(images/arrow-down.svg) no-repeat 0 50%;
}

.popup textarea {
  margin: 7px 0 0;
  width: 100%;
  height: 140px;
  padding: 7px 10px;
  border: 2px solid #ababab;
  border-radius: 2px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
}

.popup .add_image a {
  display: block;
  margin: 0 0 30px;
  padding: 10px 20px 30px;
  text-align: center;
  text-decoration: none;
  color: #33384b;
  border: 2px dashed #969696;
}

.popup .add_image .plus {
  display: block;
  font-size: 100px;
  line-height: 100px;
}

.popup .add_image a:hover {
  background: #f1f1f1;
}

.popup table.list {
  margin: 0 -15px 30px;
  width: calc(100% + 30px);
  border-collapse: collapse;
}

.popup table.list tr:nth-child(even) {
  background: #f1f1f1;
}

.popup table.list td {
  padding: 15px 15px 15px;
  text-align: left;
  vertical-align: middle;
}

/*
.popup table.list td img.avatar {display: inline-block; margin-right: 10px; width: 30px; vertical-align: middle;}
.popup table.list td span.avatar {display: inline-block; margin-right: 10px; width: 30px; height: 30px; padding: 5px 0 0; box-sizing: border-box; overflow: hidden; text-align: center; vertical-align: middle; font-size: 20px; line-height: 20px; color: #1b1c1e; background: #aaa; border-radius: 100px;}
*/

.popup table.list .control {
  text-align: right;
}

.popup table.list button {
  padding: 5px 10px;
  vertical-align: middle;
  text-decoration: none;
  font-size: 16px;
  color: white;
  background: #49a4e7;
}

.popup table.list button.remove {
  display: inline-block;
  margin-left: 20px;
  width: 20px;
  height: 20px;
  vertical-align: middle;
  background: url(images/icon-remove.svg) no-repeat 0 0;
  background-size: cover;
  box-shadow: none;
}

.popup table.list button.remove:hover {
  box-shadow: none;
  transform: scale(1.1);
}

.popup .pin_info_header {
  display: flex;
  justify-content: flex-start;
  margin: 0 0 15px;
  padding: 0 0 0 20px;
  background: url(images/arrow-right.svg) no-repeat 0 3px;
  background-size: 8px 13px;
  cursor: pointer;
}

.popup .pin_info_header:hover {
  color: #49a4e7;
}

.popup .pin_info_header.sel {
  background: url(images/arrow-down.svg) no-repeat 0 5px;
  background-size: 13px 8px;
}

.popup .pin_info_header span {
  margin-right: 20px;
}

.popup .pin_info_header button {
  position: relative;
  top: -4px;
  padding: 5px 10px;
  text-decoration: none;
  font-size: 16px;
  color: white;
  background: #49a4e7;
}

.photos {
  display: grid;
  grid-template-columns: 22% 22% 22% 22%;
  grid-template-rows: auto;
  column-gap: 4%;
  row-gap: 8%;
  margin: 0 0 70px;
}

.photos img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.photos .add {
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  color: #33384b;
  border: 2px dashed #969696;
}

.photos .add .plus {
  display: block;
  font-size: 100px;
  line-height: 100px;
}

.photos .add:hover {
  background: #f1f1f1;
}

.photos span {
  position: relative;
}

.photos span button.remove {
  position: absolute;
  right: -10px;
  top: -10px;
  width: 30px;
  height: 30px;
  background: url(images/icon-remove.svg) no-repeat 50% 50% white;
  background-size: 20px 20px;
  border-radius: 30px;
}

.popup_photo {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 102;
  text-align: center;
  background: rgba(0, 0, 0, 0.5);
}

.popup_photo img {
  max-width: 90%;
  max-height: 90%;
  vertical-align: 0;
}

.popup_photo .close {
  display: block;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 40px;
  height: 40px;
  padding: 0;
  background: url(images/icon-close.svg) no-repeat 50% 50% white;
  background-size: 20px 20px;
  border-radius: 40px;
  box-shadow: none;
}

.popup_photo .close:hover {
  box-shadow: none;
}

.popup_medium {
  position: fixed;
  left: calc(50% - 400px);
  top: 5vh;
  z-index: 102;
  width: auto;
  max-width: 800px;
  height: 90vh;
  padding: 5vh 25px 5vh 45px;
  box-sizing: border-box;
  background: white;
  border-radius: 2px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.popup_medium .close {
  display: block;
  position: absolute;
  right: 25px;
  top: 25px;
  width: 20px;
  height: 20px;
  background: url(images/icon-close.svg) no-repeat 0 0;
  box-shadow: none;
}

.popup_medium .close:hover {
  box-shadow: none;
}

.popup_medium .hdr {
  margin: 0 0 4vh;
  height: 3vh;
  font-weight: bold;
  color: #1b1c1e;
}

.popup_medium .buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 0;
  height: 60px;
}

.pdf_pages {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0 0 5vh;
  height: calc(75vh - 160px);
  overflow: scroll;
}

.pdf_pages .page {
  padding: 0 20px 20px 0;
}

.pdf_pages .page img {
  width: 100%;
}

@media (max-width: 989.98px) {
  html,
  body {
    font-size: 16px;
  }

  label {
    font-size: 14px;
  }

  input,
  textarea {
    font-size: 16px;
  }

  select {
    font-size: 16px;
  }

  button {
    font-size: 16px;
  }

  .info {
    font-size: 14px;
  }

  .header_contractor {
    padding: 20px 0;
  }

  .header_contractor h1 {
    margin: 0 0 10px;
    font-size: 45px;
    line-height: 45px;
  }

  .header_contractor h2 {
    font-size: 30px;
    line-height: 30px;
  }

  .content {
    padding: 60px 45px 120px;
    font-size: 16px;
    line-height: 23px;
  }

  .content .two_col {
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 0;
    padding-right: 0;
  }

  /* header */
  .header {
    height: 50px;
    padding: 0 45px;
    color: white;
    background: #1b1c1e;
  }

  .header .back {
    top: 15px;
  }

  .header .user {
    font-size: 16px;
  }

  /* project header */
  .header2 {
    color: white;
    background: #33384b;
  }

  .header2 ul {
    font-size: 16px;
  }

  .header2 li.hdr {
    padding-right: 70px;
    font-size: 16px;
  }

  .header2 li.hdr .status {
    font-size: 14px;
  }

  /* enter and etc */
  .login .header_login {
    padding: 20px 0;
  }

  .login .header_login h1 {
    margin: 0 0 10px;
    font-size: 45px;
    line-height: 45px;
  }

  .login .header_login h2 {
    font-size: 30px;
    line-height: 30px;
  }

  .login .content_login input[type="text"],
  .content_login input[type="password"],
  .content_login input[type="email"] {
    font-size: 16px;
  }

  .login .content_login button {
    padding: 15px;
    font-size: 16px;
  }

  /* tabs */
  .tabs {
    height: 65px;
  }

  .tabs li {
    font-size: 16px;
    line-height: 16px;
  }

  .tabs a {
    margin: 0 -10px 0 23px;
    padding: 10px 12px 7px;
  }

  .content {
    padding: 60px 45px 120px;
    font-size: 16px;
    line-height: 23px;
  }

  .content .remove {
    right: 0;
    top: 10px;
    width: 25px;
    height: 25px;
  }

  /* standart table */

  .content table.list {
    margin: 0 -10px 40px;
    width: calc(100% + 20px);
  }

  .content table.list th {
    padding: 10px;
    font-size: 14px;
  }

  .content table.list td {
    padding: 10px;
    font-size: 16px;
  }

  .content .graph table {
    font-size: 14px;
  }

  .content .graph table td.jobs {
    padding: 10px 15px;
  }

  .content .graph table td.jobs .group {
    width: 200px;
  }

  .content .graph table td.time {
    padding: 10px 15px 10px 30px;
  }

  .content .graph .add {
    width: 20px;
    height: 20px;
  }

  .content .graph .edit {
    right: 45px;
    width: 20px;
    height: 20px;
  }

  .buttons .remove {
    right: 0;
    top: 10px;
    width: 25px;
    height: 25px;
  }

  .subs {
    margin-left: -15px;
  }

  .subs .sub_group {
    margin-left: 15px;
  }

  .subs .sub_group .hdr {
    padding-left: 15px;
    font-size: 16px;
    background-position: 0 4px;
  }

  .subs .sub_item {
    margin-left: 15px;
  }

  .popup {
    left: 10px;
    right: 10px;
    width: auto;
    padding: 20px;
  }

  .popup .caption {
    margin: -20px -20px 45px;
    padding: 10px 20px 10px 20px;
    font-size: 25px;
    line-height: 25px;
  }

  .popup .caption .close {
    right: 15px;
    top: 15px;
  }

  .popup .warning {
    margin: -45px -20px 45px;
    padding: 10px 20px;
    color: white;
    background: #ff8989;
  }

  .popup table.list {
    margin-left: -10px;
    margin-right: -10px;
    width: calc(100% + 20px);
  }

  .popup table.list td {
    padding: 10px;
  }

  .popup .two_col {
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 0;
    padding-right: 0;
  }

  .popup .two_col.password {
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 819.98px) {
  .popup_medium {
    left: 10px;
    right: 10px;
    padding-left: 25px;
    padding-right: 0;
  }
}

@media (max-width: 767.98px) {
  html,
  body {
    font-size: 15px;
  }

  button {
    font-size: 15px;
  }

  input,
  textarea {
    font-size: 15px;
  }

  select {
    font-size: 15px;
  }

  /* enter and etc */
  .field {
    margin: 0 0 20px;
  }

  .content {
    padding: 0 15px 100px;
    font-size: 15px;
    line-height: 22px;
  }

  .content .button {
    padding: 10px 0;
  }

  .content .two_col {
    flex-direction: column;
    margin: 0 -10px;
  }

  .content .two_col .col {
    padding: 0 10px;
    width: auto;
  }

  .content .popup_buttons {
    justify-content: flex-start;
  }

  .buttons button {
    margin: 0 20px 0 0;
    padding: 10px 15px;
  }

  .header_contractor h1 {
    font-size: 30px;
    line-height: 30px;
  }

  .header_contractor h2 {
    font-size: 20px;
    line-height: 20px;
  }

  .header .back {
    left: 5px;
  }

  .header .back a {
    padding-left: 10px;
  }

  .header .menu {
    left: 15px;
    top: 15px;
  }

  .header .menu li {
    margin-right: 15px;
  }

  .header .menu li a {
    height: 20px;
    padding-left: 20px;
  }

  .header .menu li span {
    display: none;
  }

  .header .user {
    right: 15px;
  }

  /* project header */
  .header2 {
    padding: 0 15px 20px;
  }

  .header2 ul {
    position: relative;
  }

  .header2 ul .menu {
    display: none;
    position: absolute;
    right: -15px;
    top: 55px;
    width: 200px;
    padding: 5px 15px;
    text-align: right;
    background: #33384b;
  }

  .header2 ul .menu.opened {
    display: block;
  }

  .header2 ul .menu li {
    margin: 0 0 15px;
    padding-right: 0;
  }

  .header2 li.hdr a {
    margin-left: -11px;
    padding: 0 0 0 11px;
  }

  .header2 li.burger a {
    display: block;
    width: 35px;
    height: 20px;
    background: url(images/burger-project.svg) no-repeat 0 0;
    background-size: cover;
  }

  /* tabs */
  .tabs {
    display: block;
    height: auto;
    padding: 0 15px 15px;
  }

  .tabs li {
    display: inline-block;
    font-size: 15px;
    line-height: 15px;
  }

  .tabs a {
    display: inline-block;
    margin: 0 10px 10px 0;
    border: 1px solid white;
    border-radius: 2px;
  }

  .tabs a.sel {
    border-radius: 2px;
  }

  .plan {
    margin-left: -15px;
    margin-right: -15px;
  }

  .plan .control .remove {
    font-size: 14px;
  }

  .plan .pins {
    padding: 15px 5px 12px;
    font-size: 11px;
  }

  .plan .pins .pin img {
    margin-right: 2px;
    width: 10px;
  }

  .plan.zoomed {
    padding: 0;
  }

  .plan .info,
  .plan button.show {
    margin-left: 10px;
  }

  .content {
    padding: 25px 15px 100px;
    font-size: 15px;
    line-height: 22px;
  }

  .content .button {
    padding: 10px 0;
  }

  .content .two_col {
    display: flex;
    margin: 0 -15px;
  }

  .content .two_col .col {
    padding: 0 15px;
  }

  /* standart table */

  .content table.list {
    margin: 0 -15px 60px;
    width: calc(100% + 30px);
    border-collapse: collapse;
  }

  .content table.list th {
    display: none;
  }

  .content table.list td {
    display: block;
    padding: 10px 15px;
  }

  .content table.list .mobile {
    display: block;
  }

  .content table.list td.icon {
    padding-top: 12px;
    padding-bottom: 0;
  }

  .content table.list .icon {
    padding-right: 0;
  }

  .content table.list .icon img {
    width: 36px;
  }

  .content .only_my {
    margin: 0 0 20px;
  }

  .popup_small {
    left: 10px;
    top: 10px;
    right: 10px;
    width: auto;
    padding: 25px 15px 5px;
  }

  .popup_small .message {
    margin: 0 0 30px;
    font-size: 20px;
    line-height: 35px;
  }

  .popup_small .message.small {
    font-size: 16px;
  }

  .popup_small .buttons button {
    margin-left: 10px;
    margin-right: 10px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .popup .buttons {
    justify-content: flex-start;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .buttons button {
    margin: 0 10px;
  }

  .buttons .remove {
    top: 10px;
  }

  .popup .plan {
    margin-left: -10px;
    margin-right: -10px;
  }

  .popup {
    position: absolute;
    left: 8px;
    right: 8px;
    width: auto;
    padding: 10px;
  }

  .popup .caption {
    margin: -10px -10px 25px;
    padding: 10px 20px 10px 10px;
    font-size: 15px;
    line-height: 15px;
  }

  .popup .caption .close {
    right: 10px;
    top: 10px;
    width: 15px;
    height: 15px;
  }

  .popup .warning {
    margin: -25px -10px 25px;
    padding: 10px 10px;
    font-size: 14px;
  }

  .popup .field {
    margin: 0 0 20px;
  }

  .popup p.hdr {
    font-size: 14px;
  }

  .popup label {
    margin-right: 20px;
  }

  .popup input[type="text"],
  .popup input[type="password"],
  .popup input[type="number"],
  .popup input[type="email"] {
    margin: 5px 0 0;
    padding: 5px 7px;
    font-size: 16px;
    border: 1px solid #ababab;
  }

  .popup textarea {
    margin: 5px 0 0;
    padding: 5px 7px;
    font-size: 16px;
    border: 1px solid #ababab;
  }

  .popup select {
    border: 1px solid #ababab;
  }

  .popup .two_col {
    flex-direction: column;
    margin: 0 -10px;
  }

  .popup .two_col .col {
    padding: 0 10px;
    width: auto;
  }

  .popup .two_col span {
    margin-top: 3px;
  }

  .popup .two_col.password {
    margin-left: -10px;
    margin-right: -10px;
    padding: 15px 0;
  }

  .popup table.list {
    margin-left: -10px;
    margin-right: -10px;
    margin-top: 10px;
    width: calc(100% + 14px);
  }

  .popup table.list td {
    display: block;
    padding: 7px 10px;
    font-size: 15px;
  }

  .popup table.list td.name {
    padding-top: 12px;
    padding-bottom: 0;
  }

  .popup table.list .control {
    padding-bottom: 12px;
    text-align: left;
  }

  .popup table.list .control button {
    margin-left: 0;
    margin-right: 20px;
  }

  .popup table.list .remove {
    font-size: 14px;
  }

  .popup .pin_info_header {
    flex-direction: column;
    padding: 0 0 0 15px;
    background-position: 0 3px;
    background-size: 6px 10px;
  }

  .popup .pin_info_header.sel {
    background-position: 0 5px;
    background-size: 10px 6px;
  }

  .photos {
    grid-template-columns: 48% 48%;
    column-gap: 4%;
    row-gap: 4%;
  }

  .photos .add {
    font-size: 14px;
  }

  /* enter and etc */
  .login .header_login {
    margin-bottom: 50px;
  }

  .login .header_login h1 {
    font-size: 30px;
    line-height: 30px;
  }

  .login .header_login h2 {
    font-size: 20px;
    line-height: 20px;
  }

  .login .content_login {
    padding: 0 15px;
    width: 100%;
    max-width: 400px;
    box-sizing: border-box;
  }

  .login .content_login .err {
    font-size: 14px;
  }

  .login .content_login .info {
    font-size: 14px;
    line-height: 22px;
  }

  .login .content_login label {
    font-size: 14px;
  }

  .login .content_login input[type="text"],
  .content_login input[type="password"],
  .content_login input[type="email"] {
    font-size: 15px;
  }

  .login .content_login button {
    padding: 10px;
    font-size: 15px;
  }

  .login .content_login .eye {
    top: 10px;
  }

  .login .content_login .forgot {
    font-size: 14px;
  }
}

@media (max-width: 575.98px) {
  .content .buttons .blue {
    margin-bottom: 15px;
  }

  .content .two_col {
    flex-direction: column;
  }

  .content .two_col .col {
    width: auto;
  }

  .content .graph table td.time {
    display: none;
  }

  .content .graph table td.jobs,
  .content .graph table .hdr td.project {
    box-shadow: none;
  }

  .content .graph table td.jobs .group {
    width: 100%;
    box-sizing: border-box;
  }

  .popup_medium {
    padding-left: 10px;
    padding-right: 10px;
  }

  .popup_medium .close {
    right: 10px;
    top: 10px;
    width: 15px;
    height: 15px;
  }

  .pdf_pages {
    grid-template-columns: 1fr;
  }
  .pdf_pages .page {
    padding: 0 0 20px;
  }

  /*
	.content .photos {
    grid-template-columns: 48% 48%;
    column-gap: 4%; row-gap: 4%;
  }
	
	.content .photos .add {
    font-size: 14px;
  }
	
*/
}
