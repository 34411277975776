.react-date-picker {
  display: inline-flex !important;
  position: relative !important;
  margin-top: 7px;
  z-index: 5;
}

.react-date-picker,
.react-date-picker *,
.react-date-picker *:before,
.react-date-picker *:after {
  -moz-box-sizing: border-box !important;
  -webkit-box-sizing: border-box !important;
  box-sizing: border-box !important;
}
.react-date-picker--disabled {
  background-color: #f0f0f0 !important;
  color: #6d6d6d !important;
}
.react-date-picker__wrapper {
  display: flex !important;
  flex-grow: 1 !important;
  flex-shrink: 0 !important;
  border: 2px solid #ababab !important;
  border-radius: 2px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
}
.react-date-picker__inputGroup {
  min-width: calc((4px * 3) + 0.54em * 8 + 0.217em * 2) !important;
  flex-grow: 1 !important;
  padding: 7px 10px !important;
  box-sizing: content-box !important;
}
.react-date-picker__inputGroup__divider {
  padding: 1px 0 !important;
  white-space: pre !important;
}
.react-date-picker__inputGroup__input {
  min-width: 0.54em !important;
  height: 100% !important;
  position: relative !important;
  padding: 0 1px !important;
  border: 0 !important;
  background: none !important;
  font: inherit !important;
  box-sizing: content-box !important;
  -moz-appearance: textfield !important;
  margin: 0 !important;
  box-shadow: none !important;
}
.react-date-picker__inputGroup .react-date-picker__inputGroup__input {
  min-width: 0.54em !important;
  height: 100% !important;
  position: relative !important;
  padding: 0 1px !important;
  border: 0 !important;
  background: none !important;
  font: inherit !important;
  box-sizing: content-box !important;
  -moz-appearance: textfield !important;
}

.react-date-picker__inputGroup__input::-webkit-outer-spin-button,
.react-date-picker__inputGroup__input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}
.react-date-picker__inputGroup__input:invalid {
  background: rgba(255, 0, 0, 0.1) !important;
}
.react-date-picker__inputGroup__input--hasLeadingZero {
  margin-left: -0.54em;
  padding-left: calc(1px + 0.54em) !important;
}
.react-date-picker__button {
  border: 0 !important;
  background: transparent !important;
  padding: 4px 6px !important;
  box-shadow: none !important;
}
.react-date-picker__button:enabled {
  cursor: pointer !important;
}
.react-date-picker__button:enabled:hover .react-date-picker__button__icon,
.react-date-picker__button:enabled:focus .react-date-picker__button__icon {
  stroke: #0078d7 !important;
}
.react-date-picker__button:disabled .react-date-picker__button__icon {
  stroke: #6d6d6d !important;
}
.react-date-picker__button svg {
  display: inherit !important;
}
.react-date-picker__calendar {
  width: 350px !important;
  max-width: 100vw !important;
  position: absolute !important;
  top: 100% !important;
  left: 0 !important;
  z-index: 1 !important;
}
.react-date-picker__calendar--closed {
  display: none !important;
}
.react-date-picker__calendar .react-calendar {
  border-width: thin !important;
}

@media (max-width: 767.98px) {
  .react-date-picker__wrapper {
    display: flex !important;
    flex-grow: 1 !important;
    flex-shrink: 0 !important;
    border: 1px solid #ababab !important;
    border-radius: 2px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  }
  .react-date-picker__inputGroup {
    min-width: calc((4px * 3) + 0.54em * 8 + 0.217em * 2) !important;
    flex-grow: 1 !important;
    padding: 5px 7px !important;
    box-sizing: content-box !important;
  }
}

.react-calendar {
  width: 350px !important;
  max-width: 100% !important;
  background: white !important;
  border: 1px solid #a0a096 !important;
  font-family: Arial, Helvetica, sans-serif !important;
  line-height: 1.125em !important;
  z-index: 3;
}
.react-calendar--doubleView {
  width: 700px !important;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex !important;
  margin: -0.5em !important;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50% !important;
  margin: 0.5em !important;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box !important;
  -webkit-box-sizing: border-box !important;
  box-sizing: border-box !important;
}
.react-calendar button {
  margin: 0 !important;
  border: 0 !important;
  outline: none !important;
}
.react-calendar button:enabled:hover {
  cursor: pointer !important;
  box-shadow: none !important;
}
.react-calendar__navigation {
  height: 44px !important;
  margin-bottom: 1em !important;
}
.react-calendar__navigation button {
  min-width: 44px !important;
  background: none !important;

  color: black !important;
  padding: none !important;
  border-radius: 0 !important;
  box-shadow: none;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6 !important;

  color: black !important;
  padding: none !important;
  border-radius: 0 !important;
  box-shadow: none !important;
}
.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0 !important;

  color: black !important;
  padding: none !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  border: none !important;
}
.react-calendar__month-view__weekdays {
  text-align: center !important;
  text-transform: uppercase !important;
  font-weight: bold !important;
  font-size: 0.75em !important;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em !important;
}
.react-calendar__month-view__weekNumbers {
  font-weight: bold !important;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  font-size: 0.75em !important;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75) !important;
  z-index: 3;
}
.react-calendar__month-view__days__day--weekend {
  color: #d10000 !important;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575 !important;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em !important;
}
.react-calendar__tile {
  max-width: 100% !important;
  text-align: center !important;
  padding: 0.75em 0.5em !important;
  background: none !important;
  color: black;
  box-shadow: none !important;
  border-radius: 0 !important;
}
.react-calendar__tile:disabled {
  background-color: #f0f0f0 !important;
  background: #f0f0f0 !important;
  border: none !important;
  box-shadow: none !important;
  color: grey;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6 !important;
}
.react-calendar__tile--now {
  background: #ffff76 !important;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ffffa9 !important;
}
.react-calendar__tile--hasActive {
  background: #76baff !important;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff !important;
}
.react-calendar__tile--active {
  background: #006edc !important;
  color: white !important;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #1087ff !important;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6 !important;
}

.date-picker-calendar {
  position: fixed;
  left: 50%;
  margin-left: -175px;
  margin-right: 20px;
}
